.InputEmailTags {
  margin-bottom: 20px;
}

.tagSection {
  margin-bottom: 5px;
}

.TextFieldLabel {
  font-size: 12px;
  color: rgba($color: #000000, $alpha: 0.54);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 8px;
}
