@import "../../theme.scss";

.SignUp {
  background-image: url("../../assets/background.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &_Card {
    max-width: 500px;
    width: 100%;
    padding: 40px;
    background-color: rgba($color: $gray, $alpha: 0.6);
    display: flex;
    flex-direction: column;

    &_Content {
      flex: 1;

      &_Header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
      }

      &_Form {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        border-top: 1px solid #fff;

        span {
          color: #fff;
          margin-bottom: 5px;
        }
      }

      &_Actions {
        display: flex;
        justify-content: flex-end;

        button {
          width: 100%;
          max-width: 150px !important;
        }
      }
    }

    &_Options {
      color: white;
      display: flex;
      flex-direction: column;
      width: 100%;

      div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &_Icon {
    max-height: 80px;
  }

  &_Title {
    color: #fff;
    border-left: 1px solid #fff;
    font-size: 18px;
    padding-left: 10px;
  }

  &_Input {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px 10px;
    border: none;
    background-color: rgba($color: #fff, $alpha: 0.5);
    color: #fff;

    &:focus {
      outline: none !important;
      border-color: $pink;
      box-shadow: 0 0 10px $pink;
    }
  }
}

.error {
  font-size: 12px;
  color: $pink !important;
}
