@import "../../theme.scss";

.RoundCardNew {
  background-color: $gray;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: white;
  width: 100%;
  height: 160px;
  min-width: 300px;
  max-width: 300px;
}

.RoundCardNewText {
  color: white !important;
  text-align: center;
}

.RoundCardNewOptions {
  display: flex;
  justify-content: center;
}
