@import "../../theme.scss";

.ReceiptCard {
  background-color: $gray;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px 17px 25px 17px;
  display: flex;
  flex-direction: column;
}

.ReceiptCardTerms {
  color: white;
  margin-bottom: 5px;
}

.ReceiptCardItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}
