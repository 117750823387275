@import "../../theme.scss";

.StepperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  overflow-x: auto;
}

.StepperItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 2.5px;
}

.StepperPosition {
  width: 15px;
  height: 20px;
}

.StepperPositionImage {
  display: flex;
  height: 100%;
  background-image: url("../../assets/vector.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.StepperIcon {
  height: 10px;
  width: 10px;
  background-color: $gray-light;
  border-radius: 50%;
  margin-top: 5px;
}

.StepperIconActive {
  background-color: $pink;
}

.StepperLabel {
  color: $gray-light;
  font-size: 12px;
  margin-top: 5px;
}
.StepperLabelActive {
  color: $pink;
}
