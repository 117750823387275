.InputLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.InputLabelLabel {
  color: rgba($color: #fff, $alpha: 0.6);
}
.InputLabelValue {
  color: white;
}
