@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

body {
	font-family : 'Open Sans', sans-serif!important;
	margin: 0;
	padding: 0;
}

$pink: #F58F98;
$pink-strong: #90525A;
$black: #121212;
$gray: #2B2D33;
$gray-light: #C4C4C4;
$blue-strong: #233447;

.ant-form-item-label > label {
	color: white !important;
}

.ant-form-item-label > label .ant-form-item-optional {
    color: #a9a8a8 !important;
}

.ant-card-body{
	padding: 10px !important;
  }