.InputSlider {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.Label {
  font-size: 16px;
  color: rgba($color: #000000, $alpha: 0.54);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 8px;
}
