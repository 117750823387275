@import "../../theme.scss";

.RoundCardNew {
  background-color: $gray;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: white;
  width: 100%;
  height: 293.41px;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.RoundCardNewText {
  color: white !important;
  text-align: center;
}

.RoundCardNewOptions {
  display: flex;
  justify-content: center;
}
