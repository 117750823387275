@import "../../theme.scss";

.InputTurnSelect {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.InputTurnSelectLabel {
  font-size: 12px;
  color: rgba($color: #000000, $alpha: 0.54);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 8px;
}

.InputTurnSelectOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;

    .inputChecked {
      background-image: url("../../assets/vector.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    span {
      width: 13px;
      height: 20px;
      margin-bottom: 3px;
    }

    input[type="radio"] {
      border: 1px solid red;
    }

    .inputLabel {
      color: $pink;
    }

    label {
      text-align: center;
      color: white;
      font-size: 10px;
      margin-top: 0px;
    }
  }
}
