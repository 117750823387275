.InputSelect {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.InputSelectLabel {
  font-size: 12px;
  color: rgba($color: #ffffff, $alpha: 0.6) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 8px;
}
